<template>
  <div class="full_popup" :class="{'fullscreen': isActive !== 'login'}">
    <div class="row h-100 m-0 align-items-center justify-content-center">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThePopupStyling',
  props: ['isActive']
}
</script>

<style scoped>
.full_popup {
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
}
.full_popup.fullscreen {
  position: fixed;
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
}

@media (min-width: 992px) {
  .full_popup.fullscreen {
    position: fixed;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    left: 0;
    top: 0;
  }
}
</style>
